import React from "react";
import styled from 'styled-components';
import { navigate } from "gatsby";

import Button from "../components/Button";

import GlobalStyle from "../assets/styles/global";
import { mediaQuery } from "../assets/styles/mixins";
import { useWindowSize } from "../hooks/resizeWindow";
import TitleSection from "../components/TitleSection";

const Home: React.FC = () => {
    const size = useWindowSize();
    const renta = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/renta-symbol.svg';
    const rentaMobile = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/renta-symbol-mobile.svg';

    const handlePage = React.useCallback(() => {
        navigate("/");
    }, []);
    return (
        <>
            <Container>
                <section id="nossa-historia">
                    <img src={rentaMobile} alt="renta-symbol" />
                    <TitleSection
                        title="OPS! Página não encontrada."
                    />
                </section>
                <Button
                    onClick={handlePage}
                    hiddenIcon={true}
                    isNotFoundPage={true}
                >
                    VOLTAR PARA O INÍCIO
                </Button>
                <Footer>
                    <span> © 2021 - Renta Investimentos.</span>
                    <span>Todos os Direitos Reservados.</span>
                </Footer>
            </Container>
            <GlobalStyle />
        </>
    );
};

export default Home;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 4vh 0vw 10vh 0vw;
    background-color: ${props => props.theme.bg.darkGreen};

    button {
        color: ${props => props.theme.font.white};
        background-color: ${props => props.theme.bg.darkGreen};
        border-color: ${props => props.theme.bg.lightGreen};

        &:hover {
            color: ${props => props.theme.bg.darkGreen};
            background-color: ${props => props.theme.bg.lightGreen};
            border-color: ${props => props.theme.bg.lightGreen};
        }
    }
    
    ${mediaQuery.desktop`
        padding: 10vh 0vw 4vh 0vw;
    `}

    #nossa-historia {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        img {
            margin-left: -30px;
            max-width: 290px;
            max-height: 358.6px;
        }

        ${mediaQuery.desktop`
            
            img {
                margin-left: -50px;
                margin-right: 100px;
                max-width: 567.9px;
                max-height: 708.6px;
            }

            section {
                max-width: 60%;
                margin-left: 0;

                h2 {
                    font-size: 60px;
                }
            }
        `}
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    span {
        font-size: 12px;
        color: ${props => props.theme.font.white};
    }
`;